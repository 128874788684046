<template>
	<v-app id="default-layout" light class="tw-font-sans">
		<AcessibilityBar v-if="!$route.path.includes('agegate')" />
		<toolbar v-if="!$route.path.includes('agegate')" />
		<div id="page-content">
			<nuxt style="flex: 1; width: 100%;" />
		</div>
		<PageFooter />
		<cookie-banner />
	</v-app>
</template>

<script>
export default {
	name: 'DefaultLayout',
	components: { 
		AcessibilityBar: () => import('~/components/website/AcessibilityBar'), 
		Toolbar: () => import('~/components/website/Toolbar'), 
		CookieBanner: () => import('~/components/website/CookieBanner'), 
		PageFooter: () => import('~/components/website/PageFooter'),  
	},
	mounted(){
		this.$vuetify.theme.dark = false
	}
}
</script>

<style lang="scss" src="~/assets/css/website/index.scss" />
