export default async function ({ $axios, $config, $daytz }) {
  const edition = await $axios.$get('/edition')

  $config.register = {
    hasStarted: $daytz($config.REGISTER_START, 'isAfter'),
    hasEnded: !$daytz($config.REGISTER_END, 'isBefore'),
    start: $daytz($config.REGISTER_START),
    end: $daytz($config.REGISTER_END),
    showWinners: edition?.show_winners,
    showWinnersTime: $daytz(edition?.winners_time, null, 'YYYY-MM-DD')
  }
  $config.register.isEnabled = $config.register.hasStarted && !$config.register.hasEnded

  $config.invoices = {
    hasStarted: $daytz(edition?.register_start, 'isAfter'),
    hasEnded: !$daytz(edition?.register_end, 'isBefore'),
    start: $daytz(edition?.register_start),
    end: $daytz(edition?.register_end),
    startDate: $daytz($config.INVOICE_DATE_START, null, 'YYYY-MM-DD').startOf('day'),
    endDate: $daytz($config.INVOICE_DATE_END, null, 'YYYY-MM-DD').endOf('day')
  }
  $config.invoices.isEnabled = $config.invoices.hasStarted && !$config.invoices.hasEnded
}
