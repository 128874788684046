export default (context, inject) => {
  const {
    AUTH_USER_DATA_URL = '/user',
    AUTH_USER_LOGIN_URL = '/login',
    AUTH_USER_LOGOUT_URL = '/logout',

    AUTH_USER_HOME_ROUTE = 'projetos',
    AUTH_USER_LOGIN_ROUTE = 'auth-login',
    AUTH_USER_LOGOUT_ROUTE = 'index',

    AUTH_ADMIN_DATA_URL = '/admin/user',
    AUTH_ADMIN_LOGIN_URL = '/admin/login',
    AUTH_ADMIN_LOGOUT_URL = '/admin/logout',

    AUTH_ADMIN_HOME_ROUTE = 'admin-dashboard',
    AUTH_ADMIN_LOGIN_ROUTE = 'admin-auth-login',
    AUTH_ADMIN_LOGOUT_ROUTE = 'admin-auth-login'
  } = {}

  const endpoints = strategy => ({
    user: strategy === 'admin' ? AUTH_ADMIN_DATA_URL : AUTH_USER_DATA_URL,
    login: strategy === 'admin' ? AUTH_ADMIN_LOGIN_URL : AUTH_USER_LOGIN_URL,
    logout: strategy === 'admin' ? AUTH_ADMIN_LOGOUT_URL : AUTH_USER_LOGOUT_URL
  })
  const routes = strategy => ({
    home: strategy === 'admin' ? AUTH_ADMIN_HOME_ROUTE : AUTH_USER_HOME_ROUTE,
    login: strategy === 'admin' ? AUTH_ADMIN_LOGIN_ROUTE : AUTH_USER_LOGIN_ROUTE,
    logout: strategy === 'admin' ? AUTH_ADMIN_LOGOUT_ROUTE : AUTH_USER_LOGOUT_ROUTE
  })
  const pathTo = (menu, href, permissions) => {
    return menu.find(m => new RegExp(`^${m.href}*`).test(href) && permissions.some(p => m.permission && p.permission === m.permission.split(':')[0] && (!m.permission.split(':')[1] || p.action === m.permission.split(':')[1])))?.href
  }
  inject('auth', { routes, endpoints, pathTo })
}
