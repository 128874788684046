<template>
  <v-main class="tw-font-sans">
    <v-container class="py-15 fill-height d-flex flex-column align-center justify-center">
      <v-img :src="require('@/assets/img/img_error.png')" width="247" height="163" class="mb-10"/>
      <h1 class="section-title" v-text="error.statusCode" />
      <p class="paragraph-book" v-text="error.message" />
      <div class="text-center w-100 py-3">
        <v-btn class="primary-btn py-3" :block="!$vuetify.breakpoint.mdAndUp" :ripple="false" @click="goHome" style="min-width:185px;">
          Voltar para home
        </v-btn>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  layout ({ route }) {
    return route.path.includes('/admin') ? 'admin-dash' : 'default'
  },
  head () {
    return { title: 'Error ' + this.error.statusCode }
  },
  computed: {
    homePage () {
      return this.$route.path.includes('/admin') ? '/admin' : '/'
    }
  },
  methods: {
    goHome () {
      if (this.$route.path === '/') {
        this.$router.go()
      } else {
        this.$router.push(this.homePage)
      }
    }
  }
}
</script>
