<template>
	<v-app-bar fixed app outlined flat height="58px" dense>
		<v-app-bar-nav-icon @click.stop="$store.dispatch('drawer/toggle')" />
		<div class="tw-w-fit tw-text-base tw-leading-4 tw-font-bold">{{ $config.SITE_TITLE }}</div>
		<v-spacer />
		<toolbar-controls/>
		<gravatar v-if="user" :email="user.email" :size="36" default-img="mp" class="ml-4 rounded-circle" />
		<v-flex v-if="user" shrink class="d-flex flex-column ml-2 profile">
			<v-row v-text="user.name" no-gutters class="font-weight-black"/>
			<v-row class="text-capitalize" no-gutters>
				<span class="text--secondary">{{ $t(`TYPES.admins.${user.type}`) }}</span>
				<v-icon v-if="user.type == 'super'" color="amber" v-text="$icons.bolt" x-small class="mr-1"></v-icon>
			</v-row>
		</v-flex>
		<v-btn :ripple="false" class="ml-3 mr-3" dense small icon @click="$store.dispatch('auth/logout')">
			<v-icon v-text="$icons.logout" />
		</v-btn>
	</v-app-bar>
</template>

<script>

import Gravatar from 'vue-gravatar'

export default {
	name: 'AdminToolbar',
	components: {
		ToolbarControls: () => import('~/components/admin/ToolbarControls.vue'),
		Gravatar,
	},
	computed: {
		user () {
			return this.$store.getters['auth/user']
		},
	},
	methods: {}
}
</script>
