import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ccac0aa = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _f01fc990 = () => interopDefault(import('../pages/categorias.vue' /* webpackChunkName: "pages/categorias" */))
const _f1acbe7c = () => interopDefault(import('../pages/contato.vue' /* webpackChunkName: "pages/contato" */))
const _361e2fae = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _f63d3234 = () => interopDefault(import('../pages/jurados.vue' /* webpackChunkName: "pages/jurados" */))
const _fb906d38 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _5b6add04 = () => interopDefault(import('../pages/projeto/index.vue' /* webpackChunkName: "pages/projeto/index" */))
const _4d8b6548 = () => interopDefault(import('../pages/projetos.vue' /* webpackChunkName: "pages/projetos" */))
const _5c4a3b13 = () => interopDefault(import('../pages/regulamento.vue' /* webpackChunkName: "pages/regulamento" */))
const _1143302c = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _0c2193d0 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _38f59eab = () => interopDefault(import('../pages/admin/branches/index.vue' /* webpackChunkName: "pages/admin/branches/index" */))
const _0f1133f7 = () => interopDefault(import('../pages/admin/categories/index.vue' /* webpackChunkName: "pages/admin/categories/index" */))
const _39b1d745 = () => interopDefault(import('../pages/admin/category-types/index.vue' /* webpackChunkName: "pages/admin/category-types/index" */))
const _1029d163 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _3f8fc518 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _52c6b16c = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _641cc983 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _5b1b4510 = () => interopDefault(import('../pages/admin/editions/index.vue' /* webpackChunkName: "pages/admin/editions/index" */))
const _b2682cba = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _c9f4344c = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _5d76e61e = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _5918d157 = () => interopDefault(import('../pages/admin/grades/index.vue' /* webpackChunkName: "pages/admin/grades/index" */))
const _28b14e5b = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _39ffb687 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _2bb3cf1b = () => interopDefault(import('../pages/admin/market-jury.vue' /* webpackChunkName: "pages/admin/market-jury" */))
const _56e944df = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _761198d5 = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _d0b45fac = () => interopDefault(import('../pages/admin/rating-criteria/index.vue' /* webpackChunkName: "pages/admin/rating-criteria/index" */))
const _1a8c7bb5 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _40ab63fb = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _08197963 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _4dcc446f = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _7a921402 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _643168da = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _5029c9c2 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _2b1f9542 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _174ebf9a = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _0a6cd4b2 = () => interopDefault(import('../pages/admin/categories/create.vue' /* webpackChunkName: "pages/admin/categories/create" */))
const _243d5c19 = () => interopDefault(import('../pages/admin/category-types/create.vue' /* webpackChunkName: "pages/admin/category-types/create" */))
const _4730b19b = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _3001a7ae = () => interopDefault(import('../pages/admin/editions/create.vue' /* webpackChunkName: "pages/admin/editions/create" */))
const _5a65957b = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _7f015bed = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _f66f0058 = () => interopDefault(import('../pages/admin/rating-criteria/create.vue' /* webpackChunkName: "pages/admin/rating-criteria/create" */))
const _22c9fdbb = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _4e99612c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _206cf1ce = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _52501fa0 = () => interopDefault(import('../pages/admin/categories/edit/_id.vue' /* webpackChunkName: "pages/admin/categories/edit/_id" */))
const _2427dcdc = () => interopDefault(import('../pages/admin/category-types/edit/_id.vue' /* webpackChunkName: "pages/admin/category-types/edit/_id" */))
const _19cbcbe7 = () => interopDefault(import('../pages/admin/editions/edit/_id.vue' /* webpackChunkName: "pages/admin/editions/edit/_id" */))
const _3aeb7a74 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _b0a12734 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _3e730640 = () => interopDefault(import('../pages/admin/grades/edit/_id.vue' /* webpackChunkName: "pages/admin/grades/edit/_id" */))
const _583d328d = () => interopDefault(import('../pages/admin/rating-criteria/edit/_id.vue' /* webpackChunkName: "pages/admin/rating-criteria/edit/_id" */))
const _7bd6d2b4 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _c8f84300 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _304550ab = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _3df0c8bb = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _0d2c6706 = () => interopDefault(import('../pages/admin/projects/_id.vue' /* webpackChunkName: "pages/admin/projects/_id" */))
const _aa003146 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _5f91f5d9 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _7a09fe8e = () => interopDefault(import('../pages/projeto/edit/_id.vue' /* webpackChunkName: "pages/projeto/edit/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _5ccac0aa,
    name: "admin"
  }, {
    path: "/categorias",
    component: _f01fc990,
    name: "categorias"
  }, {
    path: "/contato",
    component: _f1acbe7c,
    name: "contato"
  }, {
    path: "/faq",
    component: _361e2fae,
    name: "faq"
  }, {
    path: "/jurados",
    component: _f63d3234,
    name: "jurados"
  }, {
    path: "/perfil",
    component: _fb906d38,
    name: "perfil"
  }, {
    path: "/projeto",
    component: _5b6add04,
    name: "projeto"
  }, {
    path: "/projetos",
    component: _4d8b6548,
    name: "projetos"
  }, {
    path: "/regulamento",
    component: _5c4a3b13,
    name: "regulamento"
  }, {
    path: "/vencedores",
    component: _1143302c,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _0c2193d0,
    name: "admin-actions"
  }, {
    path: "/admin/branches",
    component: _38f59eab,
    name: "admin-branches"
  }, {
    path: "/admin/categories",
    component: _0f1133f7,
    name: "admin-categories"
  }, {
    path: "/admin/category-types",
    component: _39b1d745,
    name: "admin-category-types"
  }, {
    path: "/admin/commands",
    component: _1029d163,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _3f8fc518,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _52c6b16c,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _641cc983,
    name: "admin-dispatches"
  }, {
    path: "/admin/editions",
    component: _5b1b4510,
    name: "admin-editions"
  }, {
    path: "/admin/email-templates",
    component: _b2682cba,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _c9f4344c,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _5d76e61e,
    name: "admin-faq"
  }, {
    path: "/admin/grades",
    component: _5918d157,
    name: "admin-grades"
  }, {
    path: "/admin/indexes",
    component: _28b14e5b,
    name: "admin-indexes"
  }, {
    path: "/admin/ips",
    component: _39ffb687,
    name: "admin-ips"
  }, {
    path: "/admin/market-jury",
    component: _2bb3cf1b,
    name: "admin-market-jury"
  }, {
    path: "/admin/permissions",
    component: _56e944df,
    name: "admin-permissions"
  }, {
    path: "/admin/projects",
    component: _761198d5,
    name: "admin-projects"
  }, {
    path: "/admin/rating-criteria",
    component: _d0b45fac,
    name: "admin-rating-criteria"
  }, {
    path: "/admin/registrations",
    component: _1a8c7bb5,
    name: "admin-registrations"
  }, {
    path: "/admin/statistics",
    component: _40ab63fb,
    name: "admin-statistics"
  }, {
    path: "/admin/users",
    component: _08197963,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _4dcc446f,
    name: "admin-winners"
  }, {
    path: "/forgot",
    component: _7a921402,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _643168da,
    name: "auth-login"
  }, {
    path: "/register",
    component: _5029c9c2,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _2b1f9542,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _174ebf9a,
    name: "admin-auth-login"
  }, {
    path: "/admin/categories/create",
    component: _0a6cd4b2,
    name: "admin-categories-create"
  }, {
    path: "/admin/category-types/create",
    component: _243d5c19,
    name: "admin-category-types-create"
  }, {
    path: "/admin/dispatches/create",
    component: _4730b19b,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/editions/create",
    component: _3001a7ae,
    name: "admin-editions-create"
  }, {
    path: "/admin/email-templates/create",
    component: _5a65957b,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _7f015bed,
    name: "admin-faq-create"
  }, {
    path: "/admin/rating-criteria/create",
    component: _f66f0058,
    name: "admin-rating-criteria-create"
  }, {
    path: "/admin/users/create",
    component: _22c9fdbb,
    name: "admin-users-create"
  }, {
    path: "/",
    component: _4e99612c,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _206cf1ce,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/categories/edit/:id?",
    component: _52501fa0,
    name: "admin-categories-edit-id"
  }, {
    path: "/admin/category-types/edit/:id",
    component: _2427dcdc,
    name: "admin-category-types-edit-id"
  }, {
    path: "/admin/editions/edit/:id?",
    component: _19cbcbe7,
    name: "admin-editions-edit-id"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _3aeb7a74,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _b0a12734,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/grades/edit/:id?",
    component: _3e730640,
    name: "admin-grades-edit-id"
  }, {
    path: "/admin/rating-criteria/edit/:id",
    component: _583d328d,
    name: "admin-rating-criteria-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _7bd6d2b4,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _c8f84300,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _304550ab,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/ips/:ip",
    component: _3df0c8bb,
    name: "admin-ips-ip"
  }, {
    path: "/admin/projects/:id",
    component: _0d2c6706,
    name: "admin-projects-id"
  }, {
    path: "/admin/registrations/:id",
    component: _aa003146,
    name: "admin-registrations-id"
  }, {
    path: "/reset/:token?",
    component: _5f91f5d9,
    name: "auth-reset-token"
  }, {
    path: "/projeto/edit/:id?",
    component: _7a09fe8e,
    name: "projeto-edit-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
