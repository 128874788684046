const Menu = [
  { header: 'Administração', permission: 'dashboard' },
  {
    title: 'Dashboard',
    icon: 'dashboard',
    href: '/admin/dashboard',
    permission: 'dashboard'
  },
  {
    href: '/admin/users/create',
    permission: 'users:create',
    hidden: true
  },
  {
    href: '/admin/users/edit/',
    permission: 'users:edit',
    hidden: true
  },
  {
    title: 'Usuários',
    icon: 'admins',
    href: '/admin/users',
    permission: 'users'
  },
  {
    title: 'Jurados de Mercado',
    icon: 'marketJury',
    href: '/admin/market-jury',
    permission: 'users'
  },
  {
    title: 'Permissões',
    icon: 'permissions',
    href: '/admin/permissions',
    permission: 'permissions'
  },
  {
    title: 'Estatísticas',
    icon: 'statistics',
    href: '/admin/statistics',
    permission: 'statistics'
  },
  {
    title: 'FAQ',
    icon: 'faq',
    href: '/admin/faq',
    permission: 'faq'
  },
  { divider: true, permission: 'calculation' },
  { header: 'Promoção' },
  { divider: true, permission: 'faq' },
  {
    title: 'Cadastros',
    icon: 'registrations',
    href: '/admin/registrations',
    permission: 'registrations'
  },
  {
    title: 'Projetos',
    icon: 'project',
    href: '/admin/projects',
    permission: 'projects'
  },
  {
    title: 'Vencedores',
    icon: 'prizes.default',
    href: '/admin/winners',
    permission: 'winners'
  },
  {
    title: 'Branches - Unimed',
    icon: 'branches',
    href: '/admin/branches',
    permission: 'branches'
  },
  {
    title: 'Edições',
    icon: 'editions',
    href: '/admin/editions',
    permission: 'editions'
  },
  {
    href: '/admin/editions/create',
    permission: 'editions:create',
    hidden: true
  },
  {
    title: 'Categorias',
    icon: 'categories',
    href: '/admin/categories',
    permission: 'categories'
  },
  {
    href: '/admin/categories/create',
    permission: 'categories:create',
    hidden: true
  },
  {
    title: 'Tipos de categoria',
    icon: 'categoryTypes',
    href: '/admin/category-types',
    permission: 'categories'
  },
  {
    href: '/admin/category-types/create',
    permission: 'categories:create',
    hidden: true
  },
  {
    title: 'Critérios de avaliação',
    icon: 'criteria',
    href: '/admin/rating-criteria',
    permission: 'criteria'
  },
  {
    href: '/admin/rating-criteria/create',
    permission: 'criteria:create',
    hidden: true
  },
  {
    title: 'Notas',
    icon: 'grades',
    href: '/admin/grades',
    permission: 'grades'
  },
  { header: 'Logs', permission: 'actions' },
  {
    title: "IP's",
    icon: 'ip',
    href: '/admin/ips',
    permission: 'ips'
  },
  {
    title: 'Emails',
    icon: 'dispatches',
    href: '/admin/dispatches',
    permission: 'dispatches'
  },
  {
    title: 'Ações',
    icon: 'actions',
    href: '/admin/actions',
    permission: 'actions'
  },
  {
    title: 'Comandos',
    icon: 'commands',
    href: '/admin/commands',
    permission: 'commands'
  },
  {
    title: 'Indexes',
    icon: 'indexes',
    href: '/admin/indexes',
    permission: 'commands'
  },
  {
    title: 'Exportações',
    icon: 'exports',
    href: '/admin/exports',
    permission: 'exports'
  },
  { header: 'Conta' },
  {
    title: 'Sair',
    icon: 'logout',
    logout: true
  }
]

export default Menu
